import React from 'react'
import Image from '@partials/Image'
import Button from '@partials/Button'

import image from '@images/404.svg'
import '@sass/pages/404.scss'

const Page404 = () => (
  <div className="column is-12 error-404 page">
    <Image src={image} alt="page-not-found" />
    <h1>404</h1>
    <p>Oops, the page you&apos;re looking doesn&apos;t exist</p>
    <Button href="/" className="has-arrow">
      Go home
    </Button>
  </div>
)

export default Page404
